.MuiAutocomplete-tag {
  //background-color: #272727;
  border-radius: 5px !important;
  // height: 23px !important;
  border: 0 !important;
  // margin-top: 5vh !important;
}

.multiselect-dropdown {
  // background: #353535 !important;
  // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  // border-radius: .7vh !important;
  font-family: Honeywell Sans Web !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  color: #f0f0f0 !important;
  min-height: 3.5vh !important;
  height: auto !important;
}

// .css-lv29ka-MuiAutocomplete-root .MuiOutlinedInput-root {
//  // height: 3.5vh;
//  height: auto;
//   span {
//     //height: 3.4vh;
//   }
// }
 .MuiOutlinedInput-root {
  // height: 3.5vh;
  height: auto;
   span {
     //height: 3.4vh;
   }
 }

.text-input-label {
  font-size: 0.8rem;
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  // margin-bottom: 8px;
}

// .css-a64g7x-MuiButtonBase-root-MuiChip-root {
//   border: 1px solid #d0d0d0;
// }
.MuiChip-root{
  height: auto !important;
}

.dropdown-option {
  span {
    font-size: 0.8rem;
  }
}
.dropdown-option:after {
  height: 0.8rem;
}

.css-1t22g0q-MuiFormHelperText-root.Mui-error {
  margin-left: 0;
}

.list-dropdown {
  background: #353535 !important;
  
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
  font-family: Honeywell Sans Web !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 0.7rem !important;
  color: #F0F0F0 !important;
  /* height: '500px' !important; */
}

.borderchip {
  color:#F0F0F0 !important;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 0.7rem !important ;
  border-radius: 0.2rem!important;
  border: 1px solid #F0F0F0!important;
  margin:3.5vh 3vh 0vh 0vh !important;
  padding: 0vh 0.3vw 0vh 0.3vw !important;
  justify-content: center !important;
  justify-self: center !important;
  text-align: center !important;
}
.wrapper_multiselect{
 .MuiOutlinedInput-root{
  height: auto !important;
  min-height: 3.5vh;
    background: #353535 !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
}
}

// .css-1j99ta6-MuiAutocomplete-root .MuiFormHelperText-root{
//   margin-top: 0 !important;
// }
// MuiFormHelperText-root{
//   margin-top: 0 !important;
// }


